let key = {};

let toasterOption = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
let Envname = "demo";

if (Envname === "prodction") {
  console.log("Set demo Config");
  const API_URL = "https://angelpupapi.wearedev.team"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    frontend: "https://www.angelpupp.com",
    ICOcontractAddress: "0x3DDe273bD86E5DD34eB8dd31308F335705A0c185", //demo
    BUSDcontractAddress: "0xe24d30397dbe4ce7757a7a4052db0b2625f67938",
    AngelPuppToken: "0x3f3fcc06e47df368ea6325b298e789113683a207",
    networkVersion: 43113,
    toasterOption: toasterOption,
    startDate: "1687170657",
    stage1: "1687170777",
    stage2: "1687170837",
    stage3: "1687170897",
    stage4: "1687170957",
    stage5: "1687171017",
    stage6: "1687171077",
    stage7: "1687171137",
    stage8: "1687171197",
    OverAllTokens: 90000000000,
    TotalBusdTokens: "11,250,000,000",
    Price1: "0.0000085",
    Price2: "0.0000090",
    Price3: "0.0000095",
    Price4: "0.0000105",
    Price5: "0.0000115",
    Price6: "0.0000125",
    Price7: "0.0000135",
    Price8: "0.0000145",
    NetworkId: 43113,
    Symbol: "AVAX",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    HashLink: "https://testnet.snowtrace.io/tx/",
  };
} else if (Envname === "demo") {
  console.log("Set DEMO Config==");
  const API_URL = "https://angelpupapi.wearedev.team"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    frontend: "https://angelpup.pages.dev",
    ICOcontractAddress: "0x9C71F5f63a15b68a0a0817A82dba771970Cb2492", //demo
    BUSDcontractAddress: "0x50a41c61cb0154601cc3eefca888ea279e06e8be",
    AngelPuppToken: "0xb6630efb9d73416ff4f77164d64108be4759d44a",
    networkVersion: 43113,
    toasterOption: toasterOption,
    // livechainid : 56,
    startDate: "1695708106",
    OverAllTokens: 90000000000,
    TotalBusdTokens: "11,250,000,000",
    Price1: "0.0000085",
    Price2: "0.0000090",
    Price3: "0.0000095",
    Price4: "0.0000105",
    Price5: "0.0000115",
    Price6: "0.0000125",
    Price7: "0.0000135",
    Price8: "0.0000145",
    NetworkId: 43113,
    Symbol: "AVAX",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    HashLink: "https://testnet.snowtrace.io/tx/",
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    frontend: "http://localhost:3000",

    ICOcontractAddress: "0x7Ce8c1B882eD7de17696B8c253d9b6636e47c112", //demo
    BUSDcontractAddress: "0x50a41c61cb0154601cc3eefca888ea279e06e8be",
    AngelPuppToken: "0x750cf6a6c0903e7b3afa9f48232a41fbbfe0884b",
    networkVersion: 43113,
    toasterOption: toasterOption,
    startDate: "1695366086",
    TotalBusdTokens: "11,250,000,000",
    OverAllTokens: 90000000000,
    Price1: "0.0000085",
    Price2: "0.0000090",
    Price3: "0.0000095",
    Price4: "0.0000105",
    Price5: "0.0000115",
    Price6: "0.0000125",
    Price7: "0.0000135",
    Price8: "0.0000145",
    NetworkId: 43113,
    Symbol: "AVAX",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    HashLink: "https://testnet.snowtrace.io/tx/",
    // rpcUrl: "https://bsc-testnet.publicnode.com",
    // HashLink: "https://testnet.bscscan.com/tx/",
  };
}

export default key;
