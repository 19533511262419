import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";

import Home from "./pages/home.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Dashboard from './pages/dashboard';

//
import { Provider } from "react-redux";
import store from "./store";
import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
import { bsc, bscTestnet, polygon, polygonMumbai,avalancheFuji } from "viem/chains";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, avalancheFuji],
  [publicProvider()]
);
console.log({ chains }, "chainschainschainswwwww");

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true,
    }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'Web3modalv2',
    //     jsonRpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/N55E4OBB31jjeOw9d6FBUVebdYu3chSY',
    //   },
    // }),
    new WalletConnectConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true,
      options: {
        projectId: "681700e231a5aef269b7fe4adb34981a",
        version: "2",
      },
    }),
    new InjectedConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true,
      options: {
        name: "Injected",
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/:id", element: <Home /> },
    // { path: "dashboard", element: <Dashboard /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <div>
      <Provider store={store}>
        <WagmiConfig config={config}>
          <ToastContainer />
          <Router basename="/">
            <App />
          </Router>
        </WagmiConfig>
      </Provider>
    </div>
  );
};

export default AppWrapper;
